/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import clsx from 'clsx';
import Calendar from 'react-calendar';

import useStyles from './styles';

function Period({ open, start, end, close, set }) {
  const classes = useStyles();

  const [period, periodSet] = useState(0);
  const [startDate, startDateSet] = useState(start);
  const [endDate, endDateSet] = useState(end);

  return (
    <Drawer anchor="bottom" open={open}>
      <div className={classes.root}>
        <div className={classes.header}>
          <ArrowBackIos fontSize="large" onClick={() => close()} />
          <span>consulta</span>
        </div>

        <div className={classes.body}>
          <div className={classes.period}>
            <span
              className={clsx(null, { selected: period === 0 })}
              onClick={() => periodSet(0)}
            >
              inicio
            </span>
            <span
              className={clsx(null, { selected: period === 1 })}
              onClick={() => periodSet(1)}
            >
              fim
            </span>
          </div>

          {period === 0 ? (
            <Calendar key={0} onChange={startDateSet} value={startDate} />
          ) : (
            <Calendar key={1} onChange={endDateSet} value={endDate} />
          )}

          <span className={classes.result}>
            {`${format(startDate, 'dd/MM/yyyy', {
              locale: ptBR,
            })} - ${format(endDate, 'dd/MM/yyyy', {
              locale: ptBR,
            })}`}
          </span>

          <button
            type="button"
            className={classes.confirm}
            onClick={() => set(startDate, endDate)}
          >
            confirmar
          </button>
        </div>
      </div>
    </Drawer>
  );
}

export default Period;
