/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { companiesRequest } from '../../store/modules/sales/actions';

import Header from './Header';
import Main from './Main';
import Menu from '../../components/Menu';
import Loading from '../../components/Loading';

function Sales() {
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const { loading } = useSelector(state => state.sales);

  useEffect(() => {
    const { id } = user;
    dispatch(
      companiesRequest({
        user_id: id,
      })
    );
  }, []);

  return (
    <>
      <Header />
      <Main />
      <Menu />
      <Loading loading={loading} />
    </>
  );
}

export default Sales;
