export function companiesRequest(parameters) {
  return {
    type: '@sales/COMPANIES_REQUEST',
    payload: { parameters },
  };
}

export function companiesFailure() {
  return {
    type: '@sales/COMPANIES_FAILURE',
  };
}

export function companiesSuccess(companies) {
  return {
    type: '@sales/COMPANIES_SUCCESS',
    payload: { companies },
  };
}
