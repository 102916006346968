import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '64px',
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    background: '#FF7A01',

    '& svg ': {
      color: '#fff',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& a ': {
      marginRight: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& span ': {
      textTransform: 'uppercase',
      color: '#fff',
      fontWeight: 600,
    },
  },
});

export default useStyles;
