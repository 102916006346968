import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: '#eee',
  },
  list: {
    width: '100%',
  },
  item: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    background: '#fff',
  },
  company: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.675rem',
    marginBottom: '20px',

    '& span': {
      flex: 1,
      margin: '0 20px',
      textTransform: 'uppercase',
    },
  },
  financial: {
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.575rem',

    '& div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'right',

      '& span': {
        textTransform: 'uppercase',
      },
    },
  },
  credits: {
    color: 'green',
    fontSize: '0.675rem',
  },
  debits: {
    color: 'red',
    fontSize: '0.675rem',
  },
  balance: {
    color: props => (props.total > 0 ? 'green' : 'red'),
    fontSize: '0.675rem',
  },
});

export default useStyles;
