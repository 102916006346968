const urlBase = () => {
  return process.env.NODE_ENV === 'development'
    ? 'https://dev.gestorfood.com'
    : 'https://app.gestorfood.com';
};

export const signin = async user => {
  const url = urlBase();

  const res = await fetch(`${url}/users/credentials/signin`, {
    method: 'POST',
    body: JSON.stringify(user),
  }).then(res => res.json());

  return res.result;
};

export const companies = async parameters => {
  const url = urlBase();

  const res = await fetch(`${url}/dash/financial`, {
    method: 'POST',
    body: JSON.stringify(parameters),
  }).then(res => res.json());

  return res.result;
};

export const categories = async parameters => {
  const url = urlBase();

  const res = await fetch(`${url}/dash/financial/categories`, {
    method: 'POST',
    body: JSON.stringify(parameters),
  }).then(res => res.json());

  return res.result;
};

export const subcategories = async parameters => {
  const url = urlBase();

  const res = await fetch(`${url}/dash/financial/subcategories`, {
    method: 'POST',
    body: JSON.stringify(parameters),
  }).then(res => res.json());

  return res.result;
};
