import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '100vh',
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    maxHeight: '60px',
    marginBottom: '30px',
  },
  sub: {
    width: '100%',
    padding: '20px 0 40px',
    display: 'flex',
    flexDirection: 'column',

    '& h1': {
      marginBottom: '10px',
      fontSize: '1.3rem',
      fontWeight: 600,
    },

    '& h2': {
      marginBottom: '10px',
      fontSize: '0.875rem',
      fontWeight: 400,
    },

    '& span': {
      maxWidth: '50px',
      border: '1px solid #FF7A01',
    },
  },
  sign: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    height: '60px',
    padding: '20px',
    marginBottom: '20px',

    border: '1px solid #c4c4c4',
    borderRadius: '10px',
    background: '#fff',
    fontWeight: 500,
  },
  submit: {
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    border: '1px solid #c4c4c4',
    borderRadius: '10px',
    background: '#FF7A01',
    color: '#fff',

    textTransform: 'uppercase',
    fontWeight: 500,
  },
});

export default useStyles;
