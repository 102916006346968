/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from '@material-ui/icons';
import { format, startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Modal from '../../../Period';
import {
  setPeriod,
  companiesRequest,
} from '../../../../store/modules/financial/actions';
import useStyles from './styles';

function Period() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, openSet] = useState(false);
  const { user } = useSelector(state => state.auth);
  const { period } = useSelector(state => state.financial);

  useEffect(() => {
    if (period) {
      const { id } = user;
      dispatch(
        companiesRequest({
          user_id: id,
          start: format(period.startDate, 'yyyy-MM-dd', {
            locale: ptBR,
          }),
          end: format(period.endDate, 'yyyy-MM-dd', {
            locale: ptBR,
          }),
        })
      );
    } else {
      const startDate = startOfMonth(new Date());
      const endDate = new Date();
      dispatch(setPeriod(startDate, endDate));
    }
  }, [period]);

  const set = (startDate, endDate) => {
    dispatch(setPeriod(startDate, endDate));
    openSet(false);
  };

  return (
    <>
      <div className={classes.root} onClick={() => openSet(true)}>
        <div className={classes.period}>
          <DateRange />
          {period && (
            <span>
              {`${format(period.startDate, 'dd/MM/yyyy', {
                locale: ptBR,
              })} - ${format(period.endDate, 'dd/MM/yyyy', {
                locale: ptBR,
              })}`}
            </span>
          )}
        </div>
      </div>

      {period && (
        <Modal
          open={open}
          start={period.startDate}
          end={period.endDate}
          close={openSet}
          set={set}
        />
      )}
    </>
  );
}

export default Period;
