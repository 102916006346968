import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '20px 20px 0',
    display: 'flex',
    flexDirection: 'column',

    background: '#eee',
  },
  title: {
    marginTop: '30px',
    textTransform: 'uppercase',
    fontSize: '0.675rem',
    fontWeight: 600,
  },
  list: {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default useStyles;
