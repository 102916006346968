import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  parameters: null,
  companies: [],
  company: null,
};

export default function sales(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@sales/COMPANIES_REQUEST': {
        const { parameters = {} } = action.payload;
        draft.parameters = parameters;
        draft.companies = [];
        draft.loading = true;
        break;
      }

      case '@sales/COMPANIES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@sales/COMPANIES_SUCCESS': {
        const { companies = [] } = action.payload;
        draft.companies = [...companies];
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
