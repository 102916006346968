import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  sub: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    background: '#FF7A01',
    color: '#fff',
    marginBottom: '20px',

    '& a': {
      color: '#fff',
    },

    '& span': {
      flex: 1,
      fontSize: '0.675rem',
      fontWeight: 600,
      padding: '20px 0',
    },
  },
  extract: {
    padding: '0 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    marginBottom: '20px',

    '& span': {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  totalDebit: {
    color: 'red',
  },
  totalCredit: {
    color: 'green',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    border: '1px solid #c4c4c4',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  itemInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.675rem',
  },
  itemInfoValues: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 500,
  },
});

export default useStyles;
