import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import { signin } from '../../../services/api';

import { signSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const user = yield call(signin, { email, password });
    if (user) {
      yield put(signSuccess(user));
      history.push('/');
    } else {
      yield put(signFailure());
      toast.error('Falha, email ou senha incorretos');
    }
  } catch (err) {
    yield put(signFailure());
    toast.error('Houve um erro inesperado. Tente novamente');
  }
}

export function signOut() {
  localStorage.clear();
  history.push('/');
}

export default all([
  takeLatest('@auth/SIGN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
