const urlBase = () => {
  return process.env.NODE_ENV === 'development'
    ? 'https://dev.gestorfood.com'
    : 'https://app.gestorfood.com';
};

export const companies = async parameters => {
  const url = urlBase();

  const res = await fetch(`${url}/dash/sales`, {
    method: 'POST',
    body: JSON.stringify(parameters),
  }).then(res => res.json());

  return res.result;
};
