import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FF7A01',
    color: '#fff',

    '& span': {
      padding: '20px 0',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
  },
  body: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  period: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& span': {
      minWidth: '120px',
      margin: '0 5px',
      padding: '15px',
      background: '#fff',
      textTransform: 'uppercase',
      textAlign: 'center',
      border: '1px solid #c4c4c4',
      borderRadius: '10px',
    },

    '& span.selected': {
      background: '#FF7A01',
      color: '#fff',
    },
  },

  result: {
    margin: '20px 0',
    fontSize: '1rem',
    fontWeight: 600,
  },
  confirm: {
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    border: '1px solid #c4c4c4',
    borderRadius: '10px',
    background: '#FF7A01',
    color: '#fff',

    textTransform: 'uppercase',
    fontWeight: 500,
  },
});

export default useStyles;
