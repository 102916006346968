import React from 'react';
import { useSelector } from 'react-redux';
import { AccountBalance } from '@material-ui/icons';
import { formatPrice } from '../../../../utils/format';
import useStyles from './styles';

function Unified() {
  const {
    formattedToday,
    formattedThisMonth,
    formattedLastMonth,
  } = useSelector(state => {
    const { companies = [] } = state.sales;

    const today = companies.reduce((a, item) => {
      return a + item.today;
    }, 0);

    const thisMonth = companies.reduce((a, item) => {
      return a + item.this_month;
    }, 0);

    const lastMonth = companies.reduce((a, item) => {
      return a + item.last_month;
    }, 0);

    return {
      formattedToday: formatPrice(today),
      formattedThisMonth: formatPrice(thisMonth),
      formattedLastMonth: formatPrice(lastMonth),
    };
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <div className={classes.item}>
          <div className={classes.company}>
            <AccountBalance />
            <span>unificado</span>
          </div>

          <div className={classes.sales}>
            <div>
              <span>hoje</span>
              <span className={classes.today}>{formattedToday}</span>
            </div>
            <div>
              <span>mês atual</span>
              <span className={classes.thisMonth}>{formattedThisMonth}</span>
            </div>
            <div>
              <span>mês passado</span>
              <span className={classes.lastMonth}>{formattedLastMonth}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unified;
