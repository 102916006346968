import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { formatPrice } from '../../../utils/format';
import history from '../../../services/history';
import { companies, categories, subcategories } from '../../../services/api';
import {
  companiesFailure,
  companiesSuccess,
  categoriesFailure,
  categoriesSuccess,
  subcategoriesFailure,
  subcategoriesSuccess,
} from './actions';

export function* companiesRequest({ payload: { parameters } }) {
  try {
    const response = yield call(companies, parameters);
    const data = response || [];
    yield put(
      companiesSuccess([
        ...data.map(item => {
          return {
            ...item,
            extract: {
              ...item.extract,
              balance: item.extract.credits - item.extract.debits,
              formattedDebits: formatPrice(item.extract.debits),
              formattedCredits: formatPrice(item.extract.credits),
              formattedBalance: formatPrice(
                item.extract.credits - item.extract.debits
              ),
            },
          };
        }),
      ])
    );
  } catch (err) {
    yield put(companiesFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* categoriesRequest({ payload: { parameters } }) {
  try {
    const response = yield call(categories, parameters);
    const data = response || [];
    yield put(
      categoriesSuccess({
        debits: {
          name: 'despesas',
          categories: data
            .filter(f => f.modality === 0)
            .map(item => {
              return {
                ...item,
                formattedTotal: formatPrice(item.total),
                percentage: parseFloat(
                  (
                    (item.total * 100) /
                    data
                      .filter(f => f.modality === 0)
                      .reduce((a, b) => a + b.total, 0)
                  ).toFixed(2)
                ),
              };
            })
            .sort((a, b) => (a.total < b.total ? 1 : -1)),
          total: data
            .filter(f => f.modality === 0)
            .reduce((a, item) => a + item.total, 0),
          formattedTotal: formatPrice(
            data
              .filter(f => f.modality === 0)
              .reduce((a, item) => a + item.total, 0)
          ),
        },
        credits: {
          name: 'receitas',
          categories: data
            .filter(f => f.modality === 1)
            .map(item => {
              return {
                ...item,
                formattedTotal: formatPrice(item.total),
                percentage: parseFloat(
                  (
                    (item.total * 100) /
                    data
                      .filter(f => f.modality === 1)
                      .reduce((a, b) => a + b.total, 0)
                  ).toFixed(2)
                ),
              };
            })
            .sort((a, b) => (a.total < b.total ? 1 : -1)),
          total: data
            .filter(f => f.modality === 1)
            .reduce((a, item) => a + item.total, 0),
          formattedTotal: formatPrice(
            data
              .filter(f => f.modality === 1)
              .reduce((a, item) => a + item.total, 0)
          ),
        },
      })
    );
    history.push('/categories');
  } catch (err) {
    yield put(categoriesFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* subcategoriesRequest({ payload: { category, parameters } }) {
  try {
    const { id, modality } = category;
    const response = yield call(subcategories, {
      ...parameters,
      category_id: id,
      modality,
    });
    const data = response || [];
    yield put(
      subcategoriesSuccess({
        ...category,
        subcategories: data
          .map(item => {
            return {
              ...item,
              formattedTotal: formatPrice(item.total),
              percentage: parseFloat(
                ((item.total * 100) / category.total).toFixed(2)
              ),
            };
          })
          .sort((a, b) => (a.total < b.total ? 1 : -1)),
      })
    );
    history.push('/subcategories');
  } catch (err) {
    yield put(subcategoriesFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export default all([
  takeLatest('@financial/COMPANIES_REQUEST', companiesRequest),
  takeLatest('@financial/CATEGORIES_REQUEST', categoriesRequest),
  takeLatest('@financial/SUBCATEGORIES_REQUEST', subcategoriesRequest),
]);
