import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '5px 0 20px',
    background: '#eee',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  period: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& span': {
      margin: '5px 0 0 5px',
      alignSelf: 'center',
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
});

export default useStyles;
