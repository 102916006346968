import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import useStyles from './styles';

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link to="/">
          <ArrowBackIos fontSize="default" />
        </Link>
        <span>resumo financeiro</span>
      </div>
    </div>
  );
}

export default Header;
