import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import useStyles from './styles';

function Extract() {
  const classes = useStyles();
  const { company, category } = useSelector(state => state.financial);

  return (
    <div className={classes.root}>
      {category && (
        <div className={classes.sub}>
          <Link to="/categories">
            <ArrowBackIos fontSize="large" />
          </Link>

          <div>
            <span className={classes.company}>{company.name}</span>
            <span className={classes.category}>{category.name}</span>
          </div>
        </div>
      )}

      {category && category.modality === 0 && (
        <div className={classes.extract}>
          <div className={classes.header}>
            <span>total</span>
            <span className={classes.totalDebit}>
              {category.formattedTotal}
            </span>
          </div>

          <ul className={classes.list}>
            {category.subcategories.map(item => (
              <li key={item.id} className={classes.item}>
                <span className={classes.itemInfo}>{item.name}</span>

                <div className={classes.itemInfoValues}>
                  <span className={classes.totalDebit}>
                    {item.formattedTotal}
                  </span>
                  <span>{`${item.percentage} %`}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {category && category.modality === 1 && (
        <div className={classes.extract}>
          <div className={classes.header}>
            <span>total</span>
            <span className={classes.totalCredit}>
              {category.formattedTotal}
            </span>
          </div>

          <ul className={classes.list}>
            {category.subcategories.map(item => (
              <li key={item.id} className={classes.item}>
                <span className={classes.itemInfo}>{item.name}</span>

                <div className={classes.itemInfoValues}>
                  <span className={classes.totalCredit}>
                    {item.formattedTotal}
                  </span>
                  <span>{`${item.percentage} %`}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Extract;
