/* eslint-disable react/prop-types */
import React from 'react';
import { Store } from '@material-ui/icons';

import useStyles from './styles';

function Company({ item }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <div className={classes.item}>
          <div className={classes.company}>
            <Store />
            <span>{item.name}</span>
          </div>

          <div className={classes.sales}>
            <div>
              <span>hoje</span>
              <span className={classes.today}>{item.formattedToday}</span>
            </div>
            <div>
              <span>mês atual</span>
              <span className={classes.thisMonth}>
                {item.formattedThisMonth}
              </span>
            </div>
            <div>
              <span>mês passado</span>
              <span className={classes.lastMonth}>
                {item.formattedLastMonth}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
