/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';

import Header from './Header';
import Main from './Main';
import Loading from '../../components/Loading';

function Financial() {
  const { loading } = useSelector(state => state.financial);

  return (
    <>
      <Header />
      <Main />
      <Loading loading={loading} />
    </>
  );
}

export default Financial;
