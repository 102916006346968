import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  user: null,
  signed: false,
  menuModal: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_SUCCESS': {
        draft.user = action.payload.user;
        draft.signed = true;
        draft.loading = false;
        break;
      }

      case '@auth/MENU': {
        draft.menuModal = !draft.menuModal;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.user = null;
        draft.signed = false;
        draft.menuModal = false;
        break;
      }

      default:
    }
  });
}
