export function setPeriod(startDate, endDate) {
  return {
    type: '@financial/SET_PERIOD',
    payload: { startDate, endDate },
  };
}

export function companiesRequest(parameters) {
  return {
    type: '@financial/COMPANIES_REQUEST',
    payload: { parameters },
  };
}

export function companiesFailure() {
  return {
    type: '@financial/COMPANIES_FAILURE',
  };
}

export function companiesSuccess(companies) {
  return {
    type: '@financial/COMPANIES_SUCCESS',
    payload: { companies },
  };
}

export function categoriesRequest(company, parameters) {
  return {
    type: '@financial/CATEGORIES_REQUEST',
    payload: { company, parameters },
  };
}

export function categoriesFailure() {
  return {
    type: '@financial/CATEGORIES_FAILURE',
  };
}

export function categoriesSuccess(extract) {
  return {
    type: '@financial/CATEGORIES_SUCCESS',
    payload: { extract },
  };
}

export function subcategoriesRequest(category, parameters) {
  return {
    type: '@financial/SUBCATEGORIES_REQUEST',
    payload: { category, parameters },
  };
}

export function subcategoriesFailure() {
  return {
    type: '@financial/SUBCATEGORIES_FAILURE',
  };
}

export function subcategoriesSuccess(category) {
  return {
    type: '@financial/SUBCATEGORIES_SUCCESS',
    payload: { category },
  };
}
