import React from 'react';
import { useSelector } from 'react-redux';

import Period from './Period';
import Unified from './Unified';
import Company from './Company';

import useStyles from './styles';

function Main() {
  const classes = useStyles();

  const { companies: list = [] } = useSelector(state => state.financial);

  return (
    <div className={classes.root}>
      <Period />
      <Unified />
      <span className={classes.title}>empresas</span>
      <ul className={classes.list}>
        {list.map(item => (
          <Company key={item.id} item={item} />
        ))}
      </ul>
    </div>
  );
}

export default Main;
