import React from 'react';
import { Link } from 'react-router-dom';
import { AttachMoney, TrendingUp } from '@material-ui/icons';

import useStyles from './styles';

function Main() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        <li className={classes.item}>
          <Link to="/sales">
            <AttachMoney />
            <span>resumo de vendas</span>
          </Link>
        </li>
        <li className={classes.item}>
          <Link to="/financial">
            <TrendingUp />
            <span>resumo financeiro</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Main;
