import { combineReducers } from 'redux';

import auth from './auth/reducer';
import financial from './financial/reducer';
import sales from './sales/reducer';

export default combineReducers({
  auth,
  financial,
  sales,
});
