import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '20px 20px 0',
    display: 'flex',
    flexDirection: 'column',

    background: '#eee',
    transform: 'translateY(-15px)',
    zIndex: 1000,
    borderRadius: '20px 20px 0 0',
  },
  title: {
    marginTop: '30px',
    textTransform: 'uppercase',
    fontSize: '0.675rem',
    fontWeight: 600,
  },
  list: {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '10px',
    background: '#fff',

    textTransform: 'uppercase',
    fontSize: '0.675rem',
    fontWeight: 600,

    '& a': {
      height: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#000',

      '& span': {
        marginLeft: '16px',
      },
    },
  },
});

export default useStyles;
