import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { PermIdentity } from '@material-ui/icons';
import { menu, signOut } from '../../store/modules/auth/actions';
import useStyles from './styles';

function Menu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menuModal, user } = useSelector(state => state.auth);

  const hanldeMenu = () => {
    dispatch(menu());
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <Drawer anchor="left" open={menuModal} onClose={hanldeMenu}>
      <div className={classes.root}>
        <div className={classes.profile}>
          <PermIdentity />
          <div className={classes.infos}>
            <h1>{user.name}</h1>
          </div>
        </div>
        <button
          type="button"
          onClick={handleSignOut}
          className={classes.submit}
        >
          sair
        </button>
      </div>
    </Drawer>
  );
}

export default Menu;
