import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { formatPrice } from '../../../utils/format';
import { companies } from '../../../services/sales';
import { companiesFailure, companiesSuccess } from './actions';

export function* companiesRequest({ payload: { parameters } }) {
  try {
    const response = yield call(companies, parameters);
    const data = response || [];
    yield put(
      companiesSuccess([
        ...data.map(item => {
          return {
            ...item,
            formattedToday: formatPrice(item.today),
            formattedThisMonth: formatPrice(item.this_month),
            formattedLastMonth: formatPrice(item.last_month),
          };
        }),
      ])
    );
  } catch (err) {
    yield put(companiesFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export default all([takeLatest('@sales/COMPANIES_REQUEST', companiesRequest)]);
