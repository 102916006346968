import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '40px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: '#FF7A01',

    '& svg ': {
      color: '#fff',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '1.1rem',
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    '& svg': {
      maxHeight: '15px',
    },
  },
  logo: {
    marginRight: '15px',
    height: '70px',
    width: '70px',
    borderRadius: '50%',
  },
  dashboard: {
    marginTop: '4px',
    fontSize: '0.575rem',
    fontWeight: 600,
  },
});

export default useStyles;
