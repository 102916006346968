/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

import Loading from '../../components/Loading';
import { subcategoriesRequest } from '../../store/modules/financial/actions';
import useStyles from './styles';

function Categories() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, parameters, company, extract } = useSelector(
    state => state.financial
  );

  const handleCategory = category => {
    dispatch(subcategoriesRequest(category, parameters));
  };

  return (
    <>
      <div className={classes.root}>
        {company && (
          <div className={classes.sub}>
            <Link to="/financial">
              <ArrowBackIos fontSize="large" />
            </Link>
            <span>{company.name}</span>
          </div>
        )}

        {extract && (
          <div className={classes.extract}>
            <div className={classes.header}>
              <span>{extract.credits.name}</span>
              <span className={classes.totalCredit}>
                {extract.credits.formattedTotal}
              </span>
            </div>

            <ul className={classes.list}>
              {extract.credits.categories.map(item => (
                <li
                  key={item.id}
                  className={classes.item}
                  onClick={() => handleCategory(item)}
                >
                  <div className={classes.itemInfo}>
                    <span className={classes.itemInfoName}>{item.name}</span>
                    <ArrowForwardIos fontSize="small" />
                  </div>

                  <div className={classes.itemInfoValues}>
                    <span className={classes.totalCredit}>
                      {item.formattedTotal}
                    </span>
                    <span>{`${item.percentage} %`}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {extract && (
          <div className={classes.extract}>
            <div className={classes.header}>
              <span>{extract.debits.name}</span>
              <span className={classes.totalDebit}>
                {extract.debits.formattedTotal}
              </span>
            </div>

            <ul className={classes.list}>
              {extract.debits.categories.map(item => (
                <li
                  key={item.id}
                  className={classes.item}
                  onClick={() => handleCategory(item)}
                >
                  <div className={classes.itemInfo}>
                    <span className={classes.itemInfoName}>{item.name}</span>

                    <ArrowForwardIos fontSize="small" />
                  </div>

                  <div className={classes.itemInfoValues}>
                    <span className={classes.totalDebit}>
                      {item.formattedTotal}
                    </span>
                    <span>{`${item.percentage} %`}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <Loading loading={loading} />
    </>
  );
}

export default Categories;
