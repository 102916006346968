import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Home from '../pages/Home';
import Financial from '../pages/Financial';
import Categories from '../pages/Categories';
import Subcategories from '../pages/Subcategories';
import Sales from '../pages/Sales';
import Sign from '../pages/Sign';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} isPrivate />
      <Route path="/financial" exact component={Financial} isPrivate />
      <Route path="/categories" exact component={Categories} isPrivate />
      <Route path="/subcategories" exact component={Subcategories} isPrivate />
      <Route path="/sales" exact component={Sales} isPrivate />
      <Route path="/sign" exact component={Sign} />
    </Switch>
  );
}
