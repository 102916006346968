import React from 'react';
import { useSelector } from 'react-redux';
import { AccountBalance } from '@material-ui/icons';
import { formatPrice } from '../../../../utils/format';
import useStyles from './styles';

function Unified() {
  const {
    total,
    formattedCredits,
    formattedDebits,
    formattedBalance,
  } = useSelector(state => {
    const { companies = [] } = state.financial;

    const credits = companies.reduce((a, item) => {
      return a + item.extract.credits;
    }, 0);

    const debits = companies.reduce((a, item) => {
      return a + item.extract.debits;
    }, 0);

    return {
      total: credits - debits,
      formattedCredits: formatPrice(credits),
      formattedDebits: formatPrice(debits),
      formattedBalance: formatPrice(credits - debits),
    };
  });
  const classes = useStyles({ total });

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <div className={classes.item}>
          <div className={classes.company}>
            <AccountBalance />
            <span>unificado</span>
          </div>

          <div className={classes.financial}>
            <div>
              <span>receitas</span>
              <span className={classes.credits}>{formattedCredits}</span>
            </div>
            <div>
              <span>despesas</span>
              <span className={classes.debits}>{formattedDebits}</span>
            </div>
            <div>
              <span>saldo</span>
              <span className={classes.balance}>{formattedBalance}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unified;
