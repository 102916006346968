import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    minHeight: '100vh',
    background: '#eee',
  },
});

export default useStyles;
