/* eslint-disable react/prop-types */
import React from 'react';

import Header from './Header';
import Main from './Main';
import Menu from '../../components/Menu';

function Home() {
  return (
    <>
      <Header />
      <Main />
      <Menu />
    </>
  );
}

export default Home;
