import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu } from '@material-ui/icons';
import logo from '../../../assets/logo-white.png';
import { menu } from '../../../store/modules/auth/actions';
import useStyles from './styles';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const hanldeMenu = () => {
    dispatch(menu());
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img src={logo} alt="logo" className={classes.logo} />
        <div className={classes.info}>
          <span>gestor food</span>
          <span className={classes.dashboard}>resumos</span>
        </div>
      </div>
      <Menu onClick={hanldeMenu} fontSize="large" />
    </div>
  );
}

export default Header;
