/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store, ArrowForwardIos } from '@material-ui/icons';

import { categoriesRequest } from '../../../../store/modules/financial/actions';
import useStyles from './styles';

function Company({ item }) {
  const classes = useStyles({ total: item.extract.balance });
  const dispatch = useDispatch();
  const { parameters } = useSelector(state => state.financial);

  return (
    <div
      className={classes.root}
      onClick={() => {
        dispatch(
          categoriesRequest(item, { ...parameters, company_id: item.id })
        );
      }}
    >
      <div className={classes.list}>
        <div className={classes.item}>
          <div className={classes.company}>
            <Store />
            <span>{item.name}</span>
            <ArrowForwardIos />
          </div>

          <div className={classes.financial}>
            <div>
              <span>receitas</span>
              <span className={classes.credits}>
                {item.extract.formattedCredits}
              </span>
            </div>
            <div>
              <span>despesas</span>
              <span className={classes.debits}>
                {item.extract.formattedDebits}
              </span>
            </div>
            <div>
              <span>saldo</span>
              <span className={classes.balance}>
                {item.extract.formattedBalance}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
