import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  period: null,
  parameters: null,
  companies: [],
  company: null,
  extract: null,
  category: null,
};

export default function financial(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@financial/SET_PERIOD': {
        const { startDate, endDate } = action.payload;
        draft.period = { startDate, endDate };
        break;
      }

      case '@financial/COMPANIES_REQUEST': {
        const { parameters = {} } = action.payload;
        draft.parameters = parameters;
        draft.companies = [];
        draft.loading = true;
        break;
      }

      case '@financial/COMPANIES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@financial/COMPANIES_SUCCESS': {
        const { companies = [] } = action.payload;
        draft.companies = [...companies];
        draft.loading = false;
        break;
      }

      case '@financial/CATEGORIES_REQUEST': {
        const { company = null, parameters = {} } = action.payload;
        draft.company = company;
        draft.parameters = parameters;
        draft.loading = true;
        break;
      }

      case '@financial/CATEGORIES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@financial/CATEGORIES_SUCCESS': {
        const { extract = null } = action.payload;
        draft.extract = extract;
        draft.loading = false;
        break;
      }

      case '@financial/SUBCATEGORIES_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@financial/SUBCATEGORIES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@financial/SUBCATEGORIES_SUCCESS': {
        const { category = null } = action.payload;
        draft.category = category;
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
